<script setup lang="ts">
const { t } = useI18n()
</script>

<template>
  <div
    class="flex flex-col-reverse justify-center items-center p-4 align-center lg:max-w-233.5 lg:justify-between lg:mx-auto lg:flex-row-reverse lg:items-start lg:py-11 lg:px-0"
  >
    <div class="text-center ml-0 mr-0 lg:mt-33">
      <div
        class="text-2xl font-400 tracking-wide mb-0 text-white flex flex-row items-center pt-8 text-center justify-center flex-wrap lg:justify-start lg:text-[28px] lg:text-left lg:pt-0"
      >
        <span class="text-[#FFC658] flex flex-row items-center mr-2.5">
          <img
            :alt="t('notifications') + ' ' + t('that-matter')"
            src="~/assets/images/home/notifications-badge.svg"
            class="mr-3 w-10 h-10 hidden lg:flex"
          />
          {{ t('notifications') }}
        </span>
        {{ t('that-matter') }}
      </div>
      <p
        class="font-500 text-lg mt-10 tracking-wide text-[#979797] max-w-full mt-4 lg:ml-13.5 lg:mb-0 lg:text-left md:max-w-115"
      >
        {{ t('send-push-notifications') }}
      </p>
    </div>

    <div
      class="flex justify-end relative object-cover w-70 justify-center lg:w-73.5"
    >
      <NuxtImg
        loading="lazy"
        :alt="t('notifications') + ' ' + t('that-matter')"
        src="/images/nft/phone-7.webp"
        class="w-73.5 h-126.25 object-cover !m-0 hidden lg:flex"
      />
      <NuxtImg
        loading="lazy"
        sizes="sm:100vw"
        :alt="t('notifications') + ' ' + t('that-matter')"
        src="/images/home/phone-7-mobile.webp"
        class="w-70 h-50 !m-0 lg:hidden"
      />
    </div>
    <img
      :alt="t('notifications') + ' ' + t('that-matter')"
      src="~/assets/images/home/notifications-badge.svg"
      class="mb-3.5 w-10 h-10 lg:hidden"
    />
  </div>
</template>
