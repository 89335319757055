<script lang="ts" setup>
const { t } = useI18n()
</script>

<template>
  <div
    class="community flex justify-center flex-col-reverse items-center pt-10 pb-5.5 lg:flex-row lg:items-start lg:max-w-245.5 lg:mx-auto lg:justify-between lg:pt-8.5 lg:pb-11"
  >
    <div class="flex-col mx-5.5 mt-6 lg:mt-36 lg:mx-0 lg:mb-0">
      <div
        class="text-2xl leading-[42px] tracking-wide text-white text-center lg:text-left sm:text-[28px]"
      >
        {{ t('enriched') }}
        <span class="text-[#E0BDFF]">{{ t('member-profiles') }} </span>
      </div>
      <div
        class="max-w-115 mt-5 text-[18px] tracking-wide text-[#CECECE] text-center text-lg lg:text-left sm:leading-[27px]"
      >
        {{ t('provide-community-members') }}
      </div>
    </div>
    <div class="member-profiles relative">
      <NuxtImg
        :alt="t('enriched') + ' ' + t('member-profiles')"
        loading="lazy"
        class="w-80 h-80.75 object-cover object-top lg:w-109.25 lg:h-134.25"
        src="/images/nft/phone-3.webp"
      />
    </div>
  </div>
</template>

<style scoped>
.community {
  @apply relative;
  &:after {
    @apply absolute w-full h-41.75 bottom-0;
    background: linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, #151515 68.05%);
    content: '';
  }
}

.member-profiles {
  &::after {
    background: linear-gradient(0deg, #151515 0%, rgba(34, 34, 34, 0) 100%);
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 120px;
    z-index: 2;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1023.9px) {
  .community {
    &:after {
      @apply flex;
    }
  }
}
</style>
